import axios from "axios";

const state = {
  dataNotifDriverAge: [],
  dataNotifDriverLicense: [],
  dataNotifAssetLicense: [],
  dataNotifAssetInspection: [],
  dataNotifKMService: [],
  dataNotifJobChangeRoute: [],
  dataNotifJobRevenueWorksheetBlank: [],

  // Flags for tracking each fetch
  isDataNotifListLoaded: false,
  isDataNotifDriverAgeLoaded: false,
  isDataNotifDriverLicenseLoaded: false,
  isDataNotifAssetLicenseLoaded: false,
  isDataNotifAssetInspectionLoaded: false,
  isDataNotifKMServiceLoaded: false,
  isDataNotifJobChangeRouteLoaded: false,
  isDataNotifJobRevenueWorksheetBlankLoaded: false,
};

const actions = {
  async fetchDataNotifDriverAge({ commit, state }) {
    if (!state.isDataNotifDriverAgeLoaded) {
      try {
        const response = await axios.get(`api/webui/admins/notif/driver_age`, {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataNotifDriverAge", response.data.data);
        commit("setDataNotifDriverAgeLoaded", true); // Set the flag after fetching
        return response;
      } catch (error) {
        console.error("Error fetching :", error);
        throw error;
      }
    }
  },
  
  async fetchDataNotifDriverLicense({ commit, state }) {
    if (!state.isDataNotifDriverLicenseLoaded) {
      try {
        const response = await axios.get(`api/webui/admins/notif/driver_license`);
        commit("setDataNotifDriverLicense", response.data.data);
        commit("setDataNotifDriverLicenseLoaded", true); // Set the flag after fetching
        return response;
      } catch (error) {
        console.error("Error fetching  :", error);
        throw error;
      }
    }
  },

  async fetchDataNotifAssetLicense({ commit, state }) {
    if (!state.isDataNotifAssetLicenseLoaded) {
      try {
        const response = await axios.get(`api/webui/admins/notif/asset_register`);
        commit("setDataNotifAssetLicense", response.data.data);
        commit("setDataNotifAssetLicenseLoaded", true); // Set the flag after fetching
        return response;
      } catch (error) {
        console.error("Error fetching :", error);
        throw error;
      }
    }
  },

  async fetchDataNotifAssetInspection({ commit, state }) {
    if (!state.isDataNotifAssetInspectionLoaded) {
      try {
        const response = await axios.get(`api/webui/admins/notif/asset_inspection`);
        commit("setDataNotifAssetInspection", response.data.data);
        commit("setDataNotifAssetInspectionLoaded", true); // Set the flag after fetching
        return response;
      } catch (error) {
        console.error("Error fetching :", error);
        throw error;
      }
    }
  },

  async fetchDataNotifKMService({ commit, state }) {
    if (!state.isDataNotifKMServiceLoaded) {
      try {
        const response = await axios.get(`api/webui/admins/notif/asset_due_service`);
        commit("setDataNotifKMService", response.data.data);
        commit("setDataNotifKMServiceLoaded", true); 

        return response;
      } catch (error) {
        console.error("Error fetching :", error);
        throw error;
      }
    }
  },

  /* Job Order */
  async fetchDataNotifJobChangeRoute({ commit, state }) {
    if (!state.isDataNotifJobChangeRouteLoaded) {
      try {
        const response = await axios.get(`api/webui/admins/notif/job_change_route`);
        commit("setDataNotifJobChangeRoute", response.data.data);
        commit("setDataNotifJobChangeRouteLoaded", true); // Set the flag after fetching
        
        return response;
      } catch (error) {
        console.error("Error fetching :", error);
        throw error;
      }
    }
  },

  async fetchDataNotifJobRevenueWorksheetBlank({ commit, state }) {
    if (!state.isDataNotifJobRevenueWorksheetBlankLoaded) {
      try {
        const response = await axios.get(`api/webui/admins/notif/job_revenue_blank`);
        commit("setDataNotifJobRevenueWorksheetBlank", response.data.data);
        commit("setDataNotifJobRevenueWorksheetBlankLoaded", true); // Set the flag after fetching
        
        return response;
      } catch (error) {
        console.error("Error fetching :", error);
        throw error;
      }
    }
  },
};

const mutations = {
  setDataNotifDriverAge(state, data) {
    state.dataNotifDriverAge = data;
  },
  setDataNotifDriverLicense(state, data) {
    state.dataNotifDriverLicense = data;
  },
  setDataNotifAssetLicense(state, data) {
    state.dataNotifAssetLicense = data;
  },
  setDataNotifAssetInspection(state, data) {
    state.dataNotifAssetInspection = data;
  },
  setDataNotifKMService(state, data) {
    state.dataNotifKMService = data;
  },
  setDataNotifJobChangeRoute(state, data) {
    state.dataNotifJobChangeRoute = data;
  },
  setDataNotifJobRevenueWorksheetBlank(state, data) {
    state.dataNotifJobRevenueWorksheetBlank = data;
  },

  // Mutations for setting flags
  setDataNotifDriverAgeLoaded(state, isLoaded) {
    state.isDataNotifDriverAgeLoaded = isLoaded;
  },
  setDataNotifDriverLicenseLoaded(state, isLoaded) {
    state.isDataNotifDriverLicenseLoaded = isLoaded;
  },
  setDataNotifAssetLicenseLoaded(state, isLoaded) {
    state.isDataNotifAssetLicenseLoaded = isLoaded;
  },
  setDataNotifAssetInspectionLoaded(state, isLoaded) {
    state.isDataNotifAssetInspectionLoaded = isLoaded;
  },
  setDataNotifKMServiceLoaded(state, isLoaded) {
    state.setDataNotifKMServiceLoaded = isLoaded;
  },
  setDataNotifJobChangeRouteLoaded(state, isLoaded) {
    state.isDataNotifJobChangeRouteLoaded = isLoaded;
  },
  setDataNotifJobRevenueWorksheetBlankLoaded(state, isLoaded) {
    state.isDataNotifJobRevenueWorksheetBlankLoaded = isLoaded;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
