// masterMiscellaneous.js
import axios from "axios";

const state = {
  dataMasterServiceClass: [],
  dataMasterProduct: [],
  dataMasterProductCategory: [],
  dataMasterPurchaseCategory: [],
  dataMasterSparepartCategory: [],
  dataMasterMeasurement: [],

  // Flags to track whether data has been fetched
  isDataMasterServiceClassLoaded: false,
  isDataMasterProductLoaded: false,
  isDataMasterProductCategoryLoaded: false,
  isDataMasterPurchaseCategoryLoaded: false,
  isDataMasterSparepartCategoryLoaded: false,
  isDataMasterMeasurementLoaded: false,
};

const actions = {
  async fetchDataMasterServiceClass({ commit, state }) {
    if (!state.isDataMasterServiceClassLoaded) {
      try {
        const response = await axios.get("api/webui/master/service_class", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterServiceClass", response.data.data);
        commit("setDataMasterServiceClassLoaded", true);
      } catch (error) {
        console.error("Error fetching master service_class data:", error);
      }
    }
  },

  async fetchDataMasterProduct({ commit, state }) {
    if (!state.isDataMasterProductLoaded) {
      try {
        const response = await axios.get("api/webui/master/product", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterProduct", response.data.data);
        commit("setDataMasterProductLoaded", true);
      } catch (error) {
        console.error("Error fetching master product data:", error);
      }
    }
  },

  async fetchDataMasterProductCategory({ commit, state }) {
    if (!state.isDataMasterProductCategoryLoaded) {
      try {
        const response = await axios.get("api/webui/master/product_category", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterProductCategory", response.data.data);
        commit("setDataMasterProductCategoryLoaded", true);
      } catch (error) {
        console.error("Error fetching master product category data:", error);
      }
    }
  },

  async fetchDataMasterPurchaseCategory({ commit, state }) {
    if (!state.isDataMasterPurchaseCategoryLoaded) {
      try {
        const response = await axios.get("api/webui/master/purchase_category", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterPurchaseCategory", response.data.data);
        commit("setDataMasterPurchaseCategoryLoaded", true);
      } catch (error) {
        console.error("Error fetching master purchase category data:", error);
      }
    }
  },

  async fetchDataMasterSparepartCategory({ commit, state }) {
    if (!state.isDataMasterSparepartCategoryLoaded) {
      try {
        const response = await axios.get("api/webui/master/sparepart_category", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterSparepartCategory", response.data.data);
        commit("setDataMasterSparepartCategoryLoaded", true);
      } catch (error) {
        console.error("Error fetching master purchase category data:", error);
      }
    }
  },

  async fetchDataMasterMeasurement({ commit, state }) {
    if (!state.isDataMasterMeasurementLoaded) {
      try {
        const response = await axios.get("api/webui/master/measurement", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterMeasurement", response.data.data);
        commit("setDataMasterMeasurementLoaded", true);
      } catch (error) {
        console.error("Error fetching master measurement data:", error);
      }
    }
  },
};

const mutations = {
  setDataMasterServiceClass(state, data) {
    state.dataMasterServiceClass = data;
  },
  setDataMasterProduct(state, data) {
    state.dataMasterProduct = data;
  },
  setDataMasterProductCategory(state, data) {
    state.dataMasterProductCategory = data;
  },
  setDataMasterPurchaseCategory(state, data) {
    state.dataMasterPurchaseCategory = data;
  },
  setDataMasterSparepartCategory(state, data) {
    state.dataMasterSparepartCategory = data;
  },
  setDataMasterMeasurement(state, data) {
    state.dataMasterMeasurement = data;
  },

  // Mutations to set flags indicating data loading status
  setDataMasterServiceClassLoaded(state, status) {
    state.isDataMasterServiceClassLoaded = status;
  },
  setDataMasterProductLoaded(state, status) {
    state.isDataMasterProductLoaded = status;
  },
  setDataMasterProductCategoryLoaded(state, status) {
    state.isDataMasterProductCategoryLoaded = status;
  },
  setDataMasterPurchaseCategoryLoaded(state, status) {
    state.isDataMasterPurchaseCategoryLoaded = status;
  },
  setDataMasterSparepartCategoryLoaded(state, status) {
    state.isDataMasterSparepartCategoryLoaded = status;
  },
  setDataMasterMeasurementLoaded(state, status) {
    state.isDataMasterMeasurementLoaded = status;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
