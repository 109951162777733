import axios from "axios";
const apiUrl = process.env.VUE_APP_API_BASE_URL;
const apiKey = process.env.VUE_APP_API_KEY;

axios.defaults.baseURL = apiUrl;
axios.defaults.withCredentials = true;
axios.defaults.headers.common["api-key"] = apiKey;

// ✅ Set anti-cache headers globally enabling for production
// if (apiUrl && apiUrl.replace(/\/$/, "") !== "http://localhost:3000") {
//     axios.defaults.headers.common["Cache-Control"] =
//         "no-cache, no-store, must-revalidate";
//     axios.defaults.headers.common["Pragma"] = "no-cache";
//     axios.defaults.headers.common["Expires"] = "0";
// }

// ✅ Add `_t` parameter to every request to prevent caching
axios.interceptors.request.use(
    (config) => {
        if (!config.params) {
            config.params = {};
        }
        config.params._t = new Date().getTime(); // Add timestamp as query param
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axios;
