import axios from "axios";

const state = {
  dataAdminAllCustomer: [],
  dataAdminCustomer: [],
  dataAdminCustomerContact: [],
  dataAdminCustomerProject: [],
  dataAdminCustomerInvoiceList: [],
  // Flags to track whether data has been fetched
  isDataAdminAllCustomerLoaded: false,
  isDataAdminCustomerLoaded: false,
  isDataAdminCustomerContactLoaded: false,
  isDataAdminCustomerProjectLoaded: false,
  isDataAdminCustomerInvoiceListLoaded: false,
};

const actions = {
  fetchDataAdminCustomer({ commit, state }, id) {
    if (!state.isDataAdminCustomerLoaded) {
      axios
        .get("api/webui/admins/customer", {
          params: {
            office_id: id,
            is_enable: true,
            is_limit: false,
          },
        })
        .then((response) => {
          commit("setDataAdminCustomer", response.data.data);
          commit("setDataAdminCustomerLoaded", true); // Set flag after data is loaded
        })
        .catch((error) => {
          console.error("Error fetching master customer data:", error);
        });
    }
  },

  fetchDataAdminAllCustomer({ commit, state }) {
    if (!state.isDataAdminCustomerLoaded) {
      axios
        .get("api/webui/admins/customer", {
          params: {
            // office_id: id,
            is_enable: true,
            is_limit: false,
          },
        })
        .then((response) => {
          commit("setDataAdminAllCustomer", response.data.data);
          commit("setDataAdminAllCustomerLoaded", true); // Set flag after data is loaded
        })
        .catch((error) => {
          console.error("Error fetching master customer data:", error);
        });
    }
  },

  fetchDataAdminCustomerContact({ commit, state }, id) {
    if (!state.isDataAdminCustomerContactLoaded) {
      axios
        .get("api/webui/admins/customer/contact", {
          params: {
            customer_id: id,
            is_enable: true,
            is_limit: false,
          },
        })
        .then((response) => {
          commit("setDataAdminCustomerContact", response.data.data);
          commit("setDataAdminCustomerContactLoaded", true); // Set flag after data is loaded
        })
        .catch((error) => {
          console.error("Error fetching master customer contact data:", error);
        });
    }
  },

  fetchDataAdminCustomerProject({ commit, state }, id) {
    if (!state.isDataAdminCustomerProjectLoaded) {
      axios
        .get("api/webui/admins/customer/project", {
          params: {
            customer_id: id,
            is_enable: true,
            is_limit: false,
          },
        })
        .then((response) => {
          commit("setDataAdminCustomerProject", response.data.data);
          commit("setDataAdminCustomerProjectLoaded", true); // Set flag after data is loaded
        })
        .catch((error) => {
          console.error("Error fetching master customer project data:", error);
        });
    }
  },

  fetchDataAdminCustomerInvoiceList(
    { commit, state },
    { status, office_id, debtor_id }
  ) {
    if (!state.isDataAdminCustomerInvoiceListLoaded) {
      axios
        .get("api/webui/cash/billing", {
          params: {
            status: status,
            office_id: office_id,
            customer_id: debtor_id,
          },
        })
        .then((response) => {
          commit("setDataAdminCustomerInvoiceList", response.data.data);
          commit("setDataAdminCustomerInvoiceListLoaded", true); // Set flag after data is loaded
        })
        .catch((error) => {
          console.error("Error fetching customer invoice list data:", error);
        });
    }
  },

  clearStore({ commit }) {
    commit("resetDataAdminCustomer");
  },
  clearProjectStore({ commit }) {
    commit("resetDataAdminCustomerProject");
  },
};

const mutations = {
  setDataAdminAllCustomer(state, data) {
    state.dataAdminAllCustomer = data;
  },

  setDataAdminCustomer(state, data) {
    state.dataAdminCustomer = data;
  },

  setDataAdminCustomerContact(state, data) {
    state.dataAdminCustomerContact = data;
  },

  setDataAdminCustomerProject(state, data) {
    state.dataAdminCustomerProject = data;
  },

  setDataAdminCustomerInvoiceList(state, data) {
    state.dataAdminCustomerInvoiceList = data;
  },

  // Mutations for setting flags after loading data
  setDataAdminCustomerLoaded(state, isLoaded) {
    state.isDataAdminCustomerLoaded = isLoaded;
  },

  setDataAdminCustomerContactLoaded(state, isLoaded) {
    state.isDataAdminCustomerContactLoaded = isLoaded;
  },

  setDataAdminCustomerProjectLoaded(state, isLoaded) {
    state.isDataAdminCustomerProjectLoaded = isLoaded;
  },

  setDataAdminCustomerInvoiceListLoaded(state, isLoaded) {
    state.isDataAdminCustomerInvoiceListLoaded = isLoaded;
  },

  resetDataAdminCustomer(state) {
    state.dataAdminCustomer = [];
    state.isDataAdminCustomerLoaded = false;
  },

  resetDataAdminCustomerProject(state) {
    state.dataAdminCustomerProject = [];
    state.isDataAdminCustomerProjectLoaded = false;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
