// masterUsers.js
import axios from "axios";

const state = {
  dataAdminUsers: [],
  dataAdminUsersDetail: [],
  // Flags to track whether data has been fetched
  isDataAdminUsersLoaded: false,
  isDataAdminUsersDetailLoaded: false,
  currentUserId: null,
};

const actions = {
  async fetchDataAdminUsers({ commit, state }) {
    if (!state.isDataAdminUsersLoaded) {
      try {
        const response = await axios.get("api/webui/admins/users", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataAdminUsers", response.data.data);
        commit("setDataAdminUsersLoaded", true); // Set the flag after fetching
        return response;
      } catch (error) {
        console.error("Error fetching users data:", error);
        throw error;
      }
    }
  },

  async fetchDataAdminUsersDetail({ commit, state }, id) {
    if (!state.isDataAdminUsersDetailLoaded || state.currentUserId !== id) {
      try {
        if (state.currentUserId !== id) {
          commit("resetDataAdminUsersDetail");
          commit("setDataAdminUsersDetailLoaded", false);
        }

        const response = await axios.get(`api/webui/admins/users/view/${id}`);

        commit("setDataAdminUsersDetail", response.data.data);
        commit("setCurrentUserId", id);
        commit("setDataAdminUsersDetailLoaded", true);
        return response;
      } catch (error) {
        console.error("Error fetching users detail data:", error);
        throw error;
      }
    }
  },
};

const mutations = {
  setDataAdminUsers(state, data) {
    state.dataAdminUsers = data;
  },

  setDataAdminUsersDetail(state, data) {
    state.dataAdminUsersDetail = data;
  },

  // Reset dataAdminUsersDetail state
  resetDataAdminUsersDetail(state) {
    state.dataAdminUsersDetail = [];
  },

  // Mutations for setting flags
  setDataAdminUsersLoaded(state, isLoaded) {
    state.isDataAdminUsersLoaded = isLoaded;
  },

  setDataAdminUsersDetailLoaded(state, isLoaded) {
    state.isDataAdminUsersDetailLoaded = isLoaded;
  },

  setCurrentUserId(state, id) {
    state.currentUserId = id;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
