// masterWarehouse.js
import axios from "axios";

const state = {
  dataMasterWarehouse: [],
  dataMasterAllSparepart: [],
  dataMasterSparepart: [],
  dataMasterTyre: [],

  // Flags to track whether data has been fetched
  isDataMasterWarehouseLoaded: false,
  isDataMasterAllSparepartLoaded: false,
  isDataMasterSparepartLoaded: false,
  isDataMasterTyreLoaded: false,
};

const actions = {
  async fetchDataMasterWarehouse({ commit, state }) {
    if (!state.isDataMasterWarehouseLoaded) {
      try {
        const response = await axios.get("api/webui/master/warehouse", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterWarehouse", response.data.data);
        commit("setDataMasterWarehouseLoaded", true);
      } catch (error) {
        console.error("Error fetching master Warehouse data:", error);
      }
    }
  },

  async fetchDataMasterAllSparepart({ commit, state }) {
    if (!state.isDataMasterAllSparepartLoaded) {
      try {
        const response = await axios.get("api/webui/master/spare_part", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterAllSparepart", response.data.data);
        commit("setDataMasterAllSparepartLoaded", true);
      } catch (error) {
        console.error("Error fetching master spare part data:", error);
      }
    }
  },

  async fetchDataMasterSparepart({ commit, state }) {
    if (!state.isDataMasterSparepartLoaded) {
      try {
        const response = await axios.get("api/webui/master/spare_part", {
          params: {
            // categories: ["Sparepart", "Accu", "Oil"],
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterSparepart", response.data.data);
        commit("setDataMasterSparepartLoaded", true);
      } catch (error) {
        console.error("Error fetching master spare part data:", error);
      }
    }
  },

  async fetchDataMasterTyre({ commit, state }) {
    if (!state.isDataMasterTyreLoaded) {
      try {
        const response = await axios.get("api/webui/master/spare_part", {
          params: {
            categories: ["Tyre"],
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterTyre", response.data.data);
        commit("setDataMasterTyreLoaded", true);
      } catch (error) {
        console.error("Error fetching master spare part data:", error);
      }
    }
  },
};

const mutations = {
  setDataMasterWarehouse(state, data) {
    state.dataMasterWarehouse = data;
  },

  setDataMasterAllSparepart(state, data) {
    state.dataMasterAllSparepart = data;
  },

  setDataMasterSparepart(state, data) {
    state.dataMasterSparepart = data;
  },

  setDataMasterTyre(state, data) {
    state.dataMasterTyre = data;
  },

  // Mutations to set flags indicating data loading status
  setDataMasterWarehouseLoaded(state, status) {
    state.isDataMasterWarehouseLoaded = status;
  },

  setDataMasterAllSparepartLoaded(state, status) {
    state.isDataMasterAllSparepartLoaded = status;
  },
  setDataMasterSparepartLoaded(state, status) {
    state.isDataMasterSparepartLoaded = status;
  },
  setDataMasterTyreLoaded(state, status) {
    state.isDataMasterTyreLoaded = status;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
